import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  data,
  Form,
  isRouteErrorResponse,
  Links,
  LinksFunction,
  LoaderFunctionArgs,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "react-router";
import stylesheet from "~/tailwind.css?url";
import flags from "react-flagpack/dist/style.css?url";
import { IntercomProvider } from "react-use-intercom";
import {
  Theme,
  ThemeBody,
  ThemeHead,
  ThemeProvider,
  useTheme,
} from "~/utils/theme-provider";
import { ThemeSession } from "~/sessions/theme.server";
import { useEffect } from "react";
import { i18n, localeCookie } from "~/i18next.server";
import { useTranslation } from "react-i18next";

import { MixPanel } from "~/utils/MixPanel";
import {
  FlashMessage,
  FlashMessageSession,
} from "./sessions/FlashMessageSession.server";
import { FlashMessageToast } from "~/components/FlashMessageToast";
import { APIProvider } from "@vis.gl/react-google-maps";
import { MingleSubmitButton } from "./components/form/MingleSubmitButton";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
  { rel: "stylesheet", href: flags },
  { rel: "manifest", href: "/manifest.webmanifest" },
];
export type RootLoaderData = {
  theme: Awaited<Theme | null>;
  locale: string;
  path: string;
  flashMessage: FlashMessage | null;
  lngs: object;
  publicKeys: {
    MIXPANEL_PROJECT_TOKEN: string;
    GOOGLE_RECAPTCHA_CLIENT: string;
    ENVIRONMENT: string;
    GOOGLE_LOCATION_API_KEY: string;
  };
};
const INTERCOM_APP_ID = "d8ylbsny";
export function useChangeLanguage(locale: string) {
  let { i18n } = useTranslation("translation");
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);
}

export const meta: MetaFunction = () => {
  return [
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, shrink-to-fit=no",
    },
    {
      charset: "utf-8",
    },
    {
      title: "Mingle Sport Homebase",
    },
    {
      name: "mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "apple-mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "msapplication-starturl",
      content: "/",
    },
  ];
};

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const themeSession = await ThemeSession(request);
  let locale = await i18n.getLocale(request);
  const flashSession = await FlashMessageSession(request);

  return data<RootLoaderData>(
    {
      flashMessage: flashSession?.getFlashMessage(),
      locale,
      theme: themeSession.getTheme(),
      publicKeys: {
        MIXPANEL_PROJECT_TOKEN:
          context?.cloudflare?.env?.MIXPANEL_PROJECT_TOKEN,
        GOOGLE_RECAPTCHA_CLIENT:
          context?.cloudflare?.env?.GOOGLE_RECAPTCHA_CLIENT,
        ENVIRONMENT: context?.cloudflare?.env?.ENVIRONMENT,
        GOOGLE_LOCATION_API_KEY:
          context?.cloudflare?.env?.GOOGLE_LOCATION_API_KEY,
      },

      lngs: {
        en: { nativeName: "English" },
        nl: { nativeName: "Nederlands" },
      },
      path: request.url,
    },
    {
      headers: [
        ["Set-Cookie", await flashSession.deleteFlashMessage()],
        ["Set-Cookie", await localeCookie.serialize(locale)],
      ],
    }
  );
};

function App() {
  const { locale, publicKeys, flashMessage } = useLoaderData<RootLoaderData>();
  const [theme] = useTheme();
  const { pathname } = useLocation();
  let { i18n, t } = useTranslation("translation");
  useChangeLanguage(locale);

  useEffect(() => {
    MixPanel.track("hbPageView", { pathname });
  }, [pathname]);

  return (
    <html
      lang={locale}
      className={`scroll-smooth h-full ${theme ?? ""} overscroll-auto `}
      data-theme={theme ?? ""}
      dir={i18n.dir()}
    >
      <head>
        <Meta />

        <ThemeHead ssrTheme={Boolean(theme)} />
        <link rel="icon" href="/favicon.ico" />
        <Links />
      </head>
      <body>
        <Outlet />

        <ThemeBody ssrTheme={Boolean(theme)} />
        <ScrollRestoration />
        <Scripts />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(publicKeys)}`,
          }}
        />
        <FlashMessageToast flashMessage={flashMessage} />
      </body>
    </html>
  );
}
// export function shouldRevalidate({
//   actionResult,
//   defaultShouldRevalidate,
// }: {
//   actionResult: any;
//   defaultShouldRevalidate: boolean;
// }) {
//   //only added in the getgoals action for the modal of setting the scores of a match
//   if (!actionResult?.shouldRevalidateTeamData) {
//     return false;
//   }
//   return true;
// }
export function ErrorBoundary() {
  const error: unknown = useRouteError();

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Oops</h1>
        <p>Status: {error.status}</p>
        <p>{error.data.message}</p>
      </div>
    );
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!

  let errorMessage =
    (error as { message?: string })?.message || "Unknown error";
  return (
    <div className="grid h-screen place-items-center">
      <div className="flex flex-col items-center max-w-lg">
        <div className="alert alert-error text-center flex flex-col gap-4">
          <h1>Uh oh ...</h1>
          <p>Something went wrong.</p>
          <p className="font-bold">{errorMessage}</p>
          <p>Refresh the page and try again</p>
          <a
            onClick={() => window.location.reload()}
            className="btn w-full whitespace-nowrap flex flex-col px-4"
          >
            Refresh
          </a>
          <p>Or you can try logout & login again</p>
          <Form method="post" action="/action/logout" className="mt-4">
            <MingleSubmitButton
              submitValue="logout"
              theme="btn-outline"
              label="logout"
              onClick={() => {
                MixPanel.track("mft_signOut_tap_button_signOut");
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default function AppWithProviders() {
  const { theme, publicKeys } = useLoaderData<typeof loader>();
  return (
    <ThemeProvider specifiedTheme={theme}>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        initializeDelay={500}
        autoBoot={true}
        onHide={() => {
          // window.Intercom("shutdown");
        }}
      >
        <APIProvider apiKey={publicKeys?.GOOGLE_LOCATION_API_KEY}>
          <App />
        </APIProvider>
      </IntercomProvider>
    </ThemeProvider>
  );
}
